<template>
	<div class="container intlcomfirm-box" style="margin-top:20px">
		<el-row type="flex" justify="space-between">
			<el-col :span="16" style="margin-right:20px;">
				<div class="pd10 notice-title mb10" v-if="!IsChinaCity">
					<p class="mb10">
						<i class="iconfont icon-zhuyishixiang icon-fail mr5"
							style="font-size:18px;margin-right:0px"></i>
						预计成功后2小时内出票。
					</p>
				</div>

				<div>
					<el-row type="flex" justify="space-between" class="fill-title">
						<div>
							<span class="iconfont icon-wodekehupsd mr10" style="font-size:20px;"></span>
							<span>乘机人信息</span>
							<span class="txt">所有信息请与证件一致</span>
						</div>
						<div>
							<block v-if="accountInfo==null">
								<span class="login-btns cursor" @click="bindJumpLogin">登录</span>
								<span class="gray6 ft12 mr10">可选择常用乘机人</span>
							</block>
							<el-button type="danger" class="select-passenger-btn" @click="bindShowOftenPassenger">选择常旅客
							</el-button>
						</div>
					</el-row>

					<div class="fill-box">
						<div class="opportunity-box">
							<el-row type="flex" :gutter="24" class="opportunity-list"
								v-for="(item, index) in form.PassengerListData" :key="index">
								<el-col :span="4">
									<div class="column  nums">
										<span class="pt15 pb15 ft12">
											第
											<strong class="ft14">{{ index + 1 }}</strong>
											位
										</span>
										<span class="adult-ticket pt5 pb5">成人</span>
									</div>
								</el-col>
								<el-col :span="16" style="font-size:14px;">
									<el-row type="flex" justify="space-between" class="pd10" align="middle">
										<el-col :span="5" style="text-align: right;margin-right:10px;">
											<span>
												<em class="point">*</em>
												姓名
											</span>
										</el-col>
										<el-col :span="18">
											<el-row type="flex" justify="space-between">
												<el-col :span="11">
													<el-input placeholder="姓(拼音)   如:ZHANG" v-model="item.Man_xing">
													</el-input>
												</el-col>
												<el-col :span="11">
													<el-input placeholder="名(拼音)   如:SAN" v-model="item.Man_ming">
													</el-input>
												</el-col>
											</el-row>
										</el-col>
									</el-row>
									<el-row type="flex" justify="space-between" class="pd10" align="middle">
										<el-col :span="5" style="text-align: right;margin-right:10px;">
											<span>
												<em class="point">*</em>
												证件类型
											</span>
										</el-col>
										<el-col :span="18">
											<el-row type="flex" justify="space-between">
												<el-col :span="24">
													<!-- <el-select v-model="value" placeholder="请选择" style="width:100%;">
														<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
													</el-select> -->
													<el-select v-model="item.CertificateType" placeholder="-- 请选择 --"
														class="arrow-d" :disabled="isDisabled">
														<el-option
															v-for="CertificateTypeItem in item.CertificateTypeList"
															:key="CertificateTypeItem.value"
															:label="CertificateTypeItem.name"
															:value="CertificateTypeItem.value"></el-option>
													</el-select>
												</el-col>
											</el-row>
										</el-col>
									</el-row>
									<el-row type="flex" justify="space-between" class="pd10" align="middle">
										<el-col :span="5" style="text-align: right;margin-right:10px;">
											<span>
												<em class="point">*</em>
												证件号码
											</span>
										</el-col>
										<el-col :span="18">
											<el-row type="flex" justify="space-between">
												<el-col :span="24">
													<el-input placeholder="证件号码" v-model="item.CertificateNo">
													</el-input>
												</el-col>
											</el-row>
										</el-col>
									</el-row>
									<el-row type="flex" justify="space-between" class="pd10" align="middle">
										<el-col :span="5" style="text-align: right;margin-right:10px;">
											<span>
												<em class="point">*</em>
												证件有效期
											</span>
										</el-col>
										<el-col :span="18">
											<el-row type="flex" justify="space-between">
												<el-col :span="24">
													<el-date-picker placeholder="YYYY-MM-DD" style="width:100%;"
														v-model="item.CertificateDate" type="date"
														value-format="yyyy-MM-dd"></el-date-picker>
												</el-col>
											</el-row>
										</el-col>
									</el-row>
									<el-row type="flex" justify="space-between" class="pd10" align="middle">
										<el-col :span="5" style="text-align: right;margin-right:10px;">
											<span>
												<em class="point">*</em>
												手机号码
											</span>
										</el-col>
										<el-col :span="18">
											<el-row type="flex" justify="space-between">
												<el-col :span="24">
													<el-input placeholder="手机号码" v-model="item.Phone">
													</el-input>
												</el-col>
											</el-row>
										</el-col>
									</el-row>
									<el-row type="flex" justify="space-between" class="pd10" align="middle">
										<el-col :span="5" style="text-align: right;margin-right:10px;">
											<span>
												<em class="point">*</em>
												出生日期
											</span>
										</el-col>
										<el-col :span="18">
											<el-row type="flex" justify="space-between">
												<el-col :span="24">
													<el-date-picker type="date" placeholder="YYYY-MM-DD"
														style="width:100%;" v-model="item.Birthday"
														value-format="yyyy-MM-dd"></el-date-picker>
												</el-col>
											</el-row>
										</el-col>
									</el-row>
									<el-row type="flex" justify="space-between" class="pd10" align="middle">
										<el-col :span="5" style="text-align: right;margin-right:10px;">
											<span>
												<em class="point">*</em>
												国籍
											</span>
										</el-col>
										<el-col :span="18">
											<el-row type="flex" justify="space-between">
												<el-col :span="24">
													<!-- <el-input placeholder="国籍"></el-input> -->

													<el-cascader class="confirm-cn" popper-class="confirm-icong"
														v-model="item.Nationality" :options="Countrys"
														:show-all-levels="false" :disabled="isDisabled" filterable>
													</el-cascader>
												</el-col>
											</el-row>
										</el-col>
									</el-row>
									<el-row type="flex" justify="space-between" class="pd10" align="middle">
										<el-col :span="5" style="text-align: right;margin-right:10px;">
											<span>
												<em class="point">*</em>
												性别
											</span>
										</el-col>
										<el-col :span="18">
											<el-row type="flex" justify="space-between">
												<el-col :span="24">
													<el-select v-model="item.Man_sex" placeholder="请选择" class="arrow-d"
														:disabled="isDisabled">
														<el-option label="男" value="1"></el-option>
														<el-option label="女" value="0"></el-option>
													</el-select>
													<!-- <el-radio v-model="radio" label="1">男</el-radio>
													<el-radio v-model="radio" label="2">女</el-radio> -->
												</el-col>
											</el-row>
										</el-col>
									</el-row>
								</el-col>
								<el-col :span="3"><span class="del" @click="delPassenger(index)">删除</span></el-col>
							</el-row>
							<div class="pd10">
								<el-button type="danger" class="select-passenger-btn" @click="addPassenger">新增乘机人
								</el-button>
							</div>
						</div>
					</div>
				</div>

				<div class="fill-in-box" v-if="hangyilist!=null&&hangyilist.length>0">
					<div>
						<el-row type="flex" justify="space-between" class="fill-title">
							<el-row type="flex" align="middle">
								<span class="iconfont icon-baoxian" style="font-size:20px;margin-right:10px;"></span>
								<span>航空意外险</span>
							</el-row>
						</el-row>
					</div>
					<div class="fill-box" style="border-bottom:1px solid #ececec;">
						<el-row type="flex" justify="space-between" align="middle" class="air-item" style="width:100%;"
							v-for="(item, index) in hangyilist" :key="index">
							<el-row type="flex" justify="space-between" style="width:100%;" align="top">
								<div>
									<el-row type="flex">
										<div @click="bindInsuranceItemClick" :data-index="index" :data-id="item.ID"
											data-typecode="hy">
											<span class="iconfont icon-duoxuanxuanzhong icon-xian"
												v-if="item.checked"></span>
											<span class="iconfont icon-checkbox" v-else></span>
										</div>
										<el-row>
											<div class="ft18 ml10 mb5">{{ item.ProductName }}</div>
											<div class="ft14">
												<span class="gray9 ml10">{{ item.ProductDescribe }}</span>
												<span class="view-detail"
													@click="bindShowInsDetailInfo(item)">查看详情</span>
											</div>
										</el-row>
									</el-row>
									<div>
										<div class="mb10 line22 ft14 mt10">
											<span class="gray9">
												<em
													class="price ml5">￥{{ item.ADTPrice }}</em>/人{{ item.SortID == 1 ? '/段' : '' }}

											</span>
											<!-- <el-row class="mt10">
												<el-checkbox-group v-model="checkList" text-color="#f1706e" fill="#f1706e">
													<el-checkbox label="PENG/GUORUI" class="gray9"></el-checkbox>
													<el-checkbox label="PENG/GUORUI" class="gray9"></el-checkbox>
												</el-checkbox-group>
											</el-row> -->
										</div>
									</div>
								</div>
								<!-- <span class="price ft14">共￥120 4份</span> -->
							</el-row>
						</el-row>
					</div>
				</div>

				<div class="fill-in-box" v-if="jingwailist.length > 0">
					<div>
						<el-row type="flex" justify="space-between" class="fill-title">
							<el-row type="flex" align="middle">
								<span class="iconfont icon-baoxian2" style="font-size:20px;margin-right:10px;"></span>
								<span class="mr10">国际旅游险</span>
								<span class="gray9 ft12">境{{ IsChinaCity ? '内' : '外' }}旅行保障，畅行无忧</span>
							</el-row>
						</el-row>
					</div>
					<div class="fill-box" style="border-bottom:1px solid #ececec;">
						<el-row type="flex" justify="space-between" align="middle" class="air-item" style="width:100%;"
							v-for="(item, index) in jingwailist" :key="index">
							<el-row type="flex" justify="space-between" style="width:100%;" align="top">
								<div>
									<el-row type="flex">
										<div @click="bindInsuranceItemClick" :data-index="index" :data-id="item.ID"
											data-typecode="jy">
											<span class="iconfont icon-duoxuanxuanzhong icon-xian"
												v-if="item.checked"></span>
											<span class="iconfont icon-checkbox" v-else></span>
										</div>
										<el-row>
											<div class="ft18 ml10 mb5">{{ item.ProductName }}</div>
											<div class="ft14">
												<span class="gray9 ml10">{{ item.ProductName }}</span>
												<span class="view-detail"
													@click="bindShowInsDetailInfo(item)">查看详情</span>
											</div>
										</el-row>
									</el-row>
									<div>
										<el-row class="mb10 line22 ft14 mt10" type="flex" align="middle">
											<span class="gray9">{{ item.ProductDescribe }}</span>
											<!-- <el-row class="mt10 ml10">
												<el-date-picker v-model="value1" type="date" placeholder="选择日期"></el-date-picker></el-row> -->
										</el-row>
									</div>
								</div>
								<div class="ml40 ft12 mr20">
									<div class="mb5 mt10">
										<span class="mr10">成人</span>
										<span
											class="price">￥{{ item.ADTPrice }}/人{{ item.SortID == 1 ? '/段' : '' }}</span>
									</div>
									<div>
										<span class="mr10">儿童</span>
										<span
											class="price">￥{{ item.CHDPrice }}/人{{ item.SortID == 1 ? '/段' : '' }}</span>
									</div>
								</div>
							</el-row>
						</el-row>
					</div>
				</div>
				<!--签证-->
				<!-- <div class="fill-in-box">
					<div>
						<el-row type="flex" justify="space-between" class="fill-title">
							<el-row type="flex" align="middle">
								<span class="iconfont icon-qianzheng" style="font-size:20px;margin-right:10px;"></span>
								<span class="mr10">签证</span>
								<span class="gray9 ft12">一对一办理快速出签</span>
							</el-row>
						</el-row>
					</div>
					
					<div class="fill-box" style="border-bottom:1px solid #ececec;">
						<el-row type="flex" justify="space-between" align="middle" class="air-item" style="width:100%;">
							<el-row type="flex" justify="space-between" style="width:100%;" align="top">
								<div>
									<el-row type="flex">
										<span class="iconfont icon-checkbox"></span>
										<span class="iconfont icon-duoxuanxuanzhong icon-xian"></span>
										<el-row>
											<div class="ft18 ml10 mb5">泰国旅游签证</div>
											<div class="ft14">
												<span class="gray9 ml10">预计工作日5-15天，有效期3个月</span>
												<span class="view-detail">查看详情</span>
											</div>
										</el-row>
									</el-row>
									<div class="mt10 line22">
										<el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
										<div style="margin: 15px 0;"></div>
										<el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
											<el-checkbox v-for="city in cities" :label="city" :key="city">{{ city }}</el-checkbox>
										</el-checkbox-group>
									</div>
								</div>
								<div class="ml40 ft12 mr20">
									<div class="mb5 mt10"><span class="price">￥155/人</span></div>
								</div>
							</el-row>
						</el-row>
					</div>
				</div> -->

				<!-- <div class="fill-in-box">
					<div>
						<el-row type="flex" justify="space-between" class="fill-title">
							<el-row type="flex" align="middle">
								<span class="iconfont icon-zuoweishu" style="font-size:20px;margin-right:10px;"></span>
								<span class="mr10">选座偏好</span>
								<span class="gray9 ft12">提前选择心仪座位</span>
							</el-row>
							<div class="choose-b" @click="clickseatShow()">
								<span>选择</span>
								<span class="iconfont icon-xiala-copy" v-if="seatShow == true"></span>
								<span class="iconfont icon-xiala" v-if="seatShow == false"></span>
							</div>
						</el-row>
					</div>
					<div class="fill-box" style="border-bottom:1px solid #ececec;" v-if="seatShow == true">
						<div class="addlr0">
							<span class="name-b">WANG/HUAN</span>
							<span class="ft12 gray9">（成人）</span>
							<span class="window-item window-item-active">靠窗</span>
							<span class="window-item">走廊</span>
							<span class="window-item">其他</span>
						</div>
						<div class="addlr0">
							<span class="name-b">WANG/HUAN</span>
							<span class="ft12 gray9">（成人）</span>
							<span class="window-item window-item-active">靠窗</span>
							<span class="window-item">走廊</span>
							<span class="window-item">其他</span>
						</div>
					</div>
				</div> -->

				<!--联系信息-->
				<div class="fill-in-box">
					<div>
						<el-row type="flex" justify="space-between" class="fill-title">
							<div>
								<span class="iconfont icon-yonghuming1"
									style="font-size:20px;margin-right:10px;"></span>
								<span>联系信息</span>
							</div>
						</el-row>
					</div>
					<div class="fill-box">
						<el-row :gutter="24" type="flex" justify="center">
							<div style="width:70%;margin-top:20px;">
								<el-row type="flex" :gutter="24" align="middle" class="mb10">
									<el-col :span="4" style="text-align: right;"><span>联系人</span></el-col>
									<el-col :span="18">
										<el-input placeholder="请填写联系人姓名" v-model="form.linkman"></el-input>
									</el-col>
								</el-row>
								<el-row type="flex" :gutter="24" align="middle" class="mb10">
									<el-col :span="4" style="text-align: right;"><span>手机号码</span></el-col>
									<el-col :span="18">
										<el-input placeholder="请填写手机号码" v-model="form.phone"></el-input>
									</el-col>
								</el-row>
								<el-row type="flex" :gutter="24" align="middle" class="mb10" v-if="accountInfo==null">
									<el-col :span="4" style="text-align: right;"><span></span></el-col>
									<el-col :span="18">
										<el-row type="flex">
											<el-input placeholder="请输入验证码" v-model="VCodeVal"></el-input>
											<el-button type="danger" v-show="show2" @click="bindGetVCode">获取验证码
											</el-button>
											<el-button v-show="!show2" class="count" type="danger">{{ count }} s
											</el-button>
										</el-row>
									</el-col>
								</el-row>
								<el-row type="flex" :gutter="24" align="middle" class="mb10">
									<el-col :span="4" style="text-align: right;"><span>电子邮箱</span></el-col>
									<el-col :span="18">
										<el-input placeholder="请填写有效的电子邮箱" v-model="form.email"></el-input>
									</el-col>
								</el-row>
							</div>
						</el-row>
					</div>
				</div>
				<div class="fill-in-box">
					<div>
						<el-row type="flex" justify="space-between" class="fill-title">
							<div>
								<span class="iconfont icon-chuqianbingpeisong"
									style="font-size:20px;margin-right:10px;"></span>
								<span>配送信息</span>
							</div>
						</el-row>
					</div>
					<div class="fill-box">
						<el-radio-group v-model="form.sendType" class="mt20 mb20">
							<el-radio :label="1">无需配送</el-radio>
							<el-radio :label="2">上门自取</el-radio>
							<el-radio :label="3">送票上门</el-radio>
						</el-radio-group>
						<div class="ft14 gray6" v-if="form.sendType == 1">无需配送,直接发送行程单到邮箱</div>
						<div class="pick-up-ticket" v-if="form.sendType == 2">
							<span class="mr20">取票地址：广东省广州市白云区黄石路黄石花园1街2号。</span>
							<span class="">电话：020-28802399</span>
						</div>
						<div class="pick-up-ticket" v-if="form.sendType == 3">
							<el-row>
								<!-- <el-col :span="10" v-for="(item, index) in [1, 2, 3, 4]" :key="index" class="mr20 mb20">
									<div class="address-inner">
										<span class="iconfont del icon-del icon-chalv-guanbi icon-guanbi2"></span>
										<p class="ft14">
											张三
											<span class="ml20">15589356596</span>
										</p>
										<p class="address">广东省广州市白云区黄石东路黄园一街2号</p>
									</div>
								</el-col> -->
								<el-input type="textarea" :rows="6" placeholder="请输入您的收货地址" v-model="form.address">
								</el-input>
							</el-row>
							<!-- <span class="add-address-btn" @click="openAdress(2)">新增地址</span> -->
						</div>
					</div>
				</div>
				<div class="fill-in-box">
					<div>
						<el-row type="flex" justify="space-between" class="fill-title">
							<div>
								<span class="iconfont icon-chalv-bianji"
									style="font-size:20px;margin-right:10px;"></span>
								<span>备注</span>
							</div>
						</el-row>
					</div>
					<div class="fill-box mt20">
						<el-input type="textarea" :rows="6" placeholder="请输入您的需求" v-model="form.remark"></el-input>
					</div>
				</div>
				<el-row type="flex" justify="center" align="middle" class="mt20 mb20">
					<div>
						<el-button type="danger" @click="bindSubmit">确认乘机信息，下一步</el-button>
					</div>
				</el-row>
				<el-row type="flex" justify="start" align="middle" class="mt20 mb20" v-if="CustomerRules!=null">
					<div>
						<div class="mt10">
							<el-checkbox v-model="isAgreeRule">已阅读</el-checkbox>
							<el-dropdown>
								  <a class="ft12 limits-color ml10">《购票须知》</a>
							      <el-dropdown-menu slot="dropdown" style="width:800px">
									  <div class="flex-wrap">
							         <block v-for="(item,index) in tiaokuanArray" :key="index">
							         	<a class="ft12 limits-i ml10" @click="bindToTiaokuan(item)">{{item.name}}</a>
							         </block>
									 </div>
							      </el-dropdown-menu>
							</el-dropdown>
							
						
							<block v-for="(item,index) in CustomerRules" :key="index" >
								<a class="ft12 limits-color ml10" @click="bindRule(item)" v-if="index==0">{{item.Title}}</a>
							</block>
              <el-dropdown>
								  <a class="ft12 limits-color ml10">《旅客和行李运输总条件》</a>
							      <el-dropdown-menu slot="dropdown" style="width:800px">
									  <div class="flex-wrap">
							         <block v-for="(item,index) in CustomerRules" :key="index">
								        <a class="ft12 limits-i ml10" @click="bindRule(item)"  v-if="index!=0">{{item.Title}}</a>
							        </block>
									 </div>
							      </el-dropdown-menu>
							</el-dropdown>
								
						</div>
					</div>
				</el-row>
			</el-col>

			<el-col :span="8">
				<div class="right-confirm-box">
					<div class="flight-details-box">
						<div class="flight-details-list" v-for="(item, index) in BookFlightRouting" :key="index">
							<el-row type="flex" justify="space-between" class="ft16 flight-details-tit">
								<div>
									<div>
										<span>第 {{ item.SegRef }}
											程：{{ item.DepartureName }}-{{ item.ArriveName }}</span>
									</div>
									<div class="ft12 gray9 mt5">
										<span>{{ getDataStr(item.trips[0].DepartureDate,"MM-dd") }}</span>
										<span>{{ getDataStr(item.trips[0].DepartureDate,"WWW") }}</span>
										<block v-if="IsChinaCity">
											<!-- <a class="ml10" href="javascript:;">[退改签]</a> -->
											<el-link type="danger" class="ml10 ft12" @click="bindShowTGQ(item)">
												退改签</el-link>
										</block>
									</div>
								</div>
								<el-row type="flex">
									<span class="ft12 gray6 mr20 mt5">{{ item.BerthStateName }}</span>
									<span class="refresh-btn" @click="bindReSearch">[重新选择]</span>
								</el-row>
							</el-row>
							<div class="inlt-flight-box">
								<el-row type="flex">
									<el-col :span="8">
										<div class="timebox tr">
											<div class="time">{{ item.trips[0].DepartureTime }}</div>
											<div class="airport">
												{{ item.trips[0].DepartureName }}{{ item.trips[0].DepartureTerminal }}
											</div>
										</div>
									</el-col>
									<el-col :span="6" class="ml10 mr10">
										<div class="translatebox">
											<i class="change-num" v-if="item.trips[0].StopAirport != ''">经停</i>
											<i class="change-num" v-else-if="item.trips[0].Stop != ''&&item.trips[0].Stop != '0'">经停{{item.trips[0].Stop}}次</i>
											<i class="change-num"
												v-else-if="item.TurnaroundCount != ''">转机{{item.TurnaroundCount}}次</i>
											<i class="change-num" v-else>直飞</i>
											<em class="change-sym" style="width: 120px;"></em>
											<i class="change-city"
												v-if="item.trips[0].StopAirport != ''">{{ item.trips[0].StopAirportName }}</i>
											<i class="change-city"
												v-else-if="item.TurnaroundCity != ''">{{ item.TurnaroundCity }}</i>
										</div>
									</el-col>
									<el-col :span="8">
										<div class="timebox">
											<div class="time">{{ item.trips[item.trips.length - 1].ArriveTime }}</div>
											<div class="airport">
												{{ item.trips[item.trips.length - 1].ArriveName }}{{ item.trips[item.trips.length - 1].ArrivalTerminal }}
											</div>
										</div>
									</el-col>
								</el-row>
								<div class="inlt-detail cursor" @click="showDetail" :data-id="item.ID">
									详情
									<span class="iconfont icon-xiala-copy" v-if="item.ID == currentItem"></span>
									<span class="iconfont icon-xiala" v-if="item.ID != currentItem"></span>
								</div>
							</div>
							<div class="flight-details-content pb10 flight-details-content2"
								v-if="item.ID == currentItem">
								<block v-for="(item_trip, index2) in item.trips" :key="index2">
									<div class="flight-detail ft12">
										<div class="titbox">
											<img :src="item_trip.AirlineImg" />
											<span>{{ item_trip.AirlineName }}</span>
											<span class="ml20">{{ item_trip.FlightNO }}
												{{ item_trip.AirplaneKind }}</span>
											<span class="fr">{{ item.BillBerthDescription }}</span>
										</div>
										<el-row type="flex" :gutter="24" align="middle">
											<el-col :span="5">
												<div class="timebox">
													<div>{{ item_trip.DepartureTime }}</div>
													<div>{{ item_trip.ArriveTime }}{{ item_trip.days }}</div>
												</div>
											</el-col>
											<el-col :span="6">
												<div class="datebox">
													<div>{{ getDataStr(item_trip.DepartureDate,"MM-dd") }}</div>
													<div>{{ getDataStr(item_trip.ArriveDate,"MM-dd") }}</div>
												</div>
											</el-col>
											<el-col :span="11">
												<div class="airportbox">
													<span class="clearfix">
														<em class="fl">
															{{ item_trip.DepartureName }}
															<i class="terminal">{{ item_trip.DepartureTerminal }}</i>
														</em>
													</span>
													<span class="clearfix">
														<em class="fl">
															{{ item_trip.ArriveName }}
															<i class="terminal">{{ item_trip.ArrivalTerminal }}</i>
														</em>
													</span>
												</div>
											</el-col>
										</el-row>
									</div>
									<div class="change-list" v-if="item_trip.StayLishi != ''">
										<div class="txt">
											<span class="">{{ item_trip.StayCityName }}</span>
											中转
											<span class="ml15">停留：{{ item_trip.StayLishi }}</span>
										</div>
									</div>
								</block>
							</div>
						</div>

						<div class="flight-details-list" style="padding:10px"
							v-if="BookFlightRouting!=null&&BookFlightRouting.length>0">
							<el-row type="flex" justify="end">
								<div>
									<el-dropdown class="mr20">
										<span class="change-back" style="color:#333">行程单</span>
										<el-dropdown-menu slot="dropdown" style="width:200px;">
											<el-dropdown-item>提供行程单</el-dropdown-item>
										</el-dropdown-menu>
									</el-dropdown>
									<el-dropdown class="mr20" v-if="BookFlightRouting[0].CondBaggage!=''">
										<span class="change-back" style="color:#f44848">行李托运</span>
										<el-dropdown-menu slot="dropdown" style="width:200px;">
											<el-dropdown-item>{{BookFlightRouting[0].CondBaggage}}</el-dropdown-item>
										</el-dropdown-menu>
									</el-dropdown>
									<el-dropdown v-if="!IsChinaCity">
										<span class="change-back" style="color:#f44848">退改签</span>
										<el-dropdown-menu slot="dropdown" style="width:200px;">
											<el-dropdown-item>{{ LimitCondition }}</el-dropdown-item>
										</el-dropdown-menu>
									</el-dropdown>
								</div>
							</el-row>
						</div>
					</div>

					<div :class="gotop == true ? 'mt20 flight-price-box fixed-top' : 'mt20 flight-price-box'">
						<el-row type="flex" justify="space-between" class="hd">
							<div>应付总额</div>
							<div style="font-weight:700;font-size:35px;">
								<span class="price">
									<em class="ft12">¥</em>
									{{ priceInfo.TotalPrice }}
								</span>
							</div>
						</el-row>

						<div class="pricebox pb20">
							<div class="item-box">
								<div class="item-list clearfix fb">
									<div class="item-before tr">成人总价</div>
									<div class="item-line"></div>
									<div class="item-after">
										<em class="ft12">¥</em>
										{{ (priceInfo.AdultPrice + priceInfo.AdultTax)}}
									</div>
								</div>
								<div class="item-list clearfix">
									<div class="item-before tr">成人票价</div>
									<div class="item-line"></div>
									<div class="item-after">
										<em class="ft12">¥</em>
										{{ priceInfo.AdultPrice }}
										<!-- <em class="ft12">X{{priceInfo.AdultCount}}</em> -->
									</div>
								</div>
								<div class="item-list clearfix">
									<div class="item-before tr">参考税费</div>
									<div class="item-line"></div>
									<div class="item-after">
										<em class="ft12">¥</em>
										{{ priceInfo.AdultTax }}
										<!-- <em class="ft12">X{{priceInfo.AdultCount}}</em> -->
									</div>
								</div>
								<!-- <div class="item-list clearfix" v-if="selectInsList!=null&&selectInsList.length>0">
									<div class="item-before tr">保险费用</div>
									<div class="item-line"></div>
									<div class="item-after">
										<em class="ft12">¥</em>
										{{ InsureTotalPrice }}										
									</div>
								</div> -->
								<div class="item-list clearfix">
									<div class="item-before tr">人数</div>
									<div class="item-line"></div>
									<div class="item-after">
										<em class="ft12">{{ priceInfo.AdultCount }}</em>
									</div>
								</div>
							</div>

							<div class="item-box" v-if="priceInfo.ChildCount > 0">
								<div class="item-list clearfix fb">
									<div class="item-before tr">儿童总价</div>
									<div class="item-line"></div>
									<div class="item-after">
										<em class="ft12">¥</em>
										{{ (priceInfo.ChildPrice + priceInfo.ChildTax) * priceInfo.ChildCount }}
									</div>
								</div>
								<div class="item-list clearfix">
									<div class="item-before tr">儿童票价</div>
									<div class="item-line"></div>
									<div class="item-after">
										<em class="ft12">¥</em>
										{{ priceInfo.ChildPrice }}
										<!-- <em class="ft12">X{{ priceInfo.ChildCount }}</em> -->
									</div>
								</div>
								<div class="item-list clearfix">
									<div class="item-before tr">参考税费</div>
									<div class="item-line"></div>
									<div class="item-after">
										<em class="ft12">¥</em>
										{{ priceInfo.ChildTax }}
										<!-- <em class="ft12">X{{ priceInfo.ChildCount }}</em> -->
									</div>
								</div>
								<!-- <div class="item-list clearfix" v-if="selectInsList!=null&&selectInsList.length>0">
									<div class="item-before tr">保险费用</div>
									<div class="item-line"></div>
									<div class="item-after">
										<em class="ft12">¥</em>
										{{ InsureTotalPrice }}
									</div>
								</div> -->
								<div class="item-list clearfix">
									<div class="item-before tr">人数</div>
									<div class="item-line"></div>
									<div class="item-after">
										<em class="ft12">{{ priceInfo.ChildCount }}</em>
									</div>
								</div>
							</div>

							<div class="item-box" v-if="selectInsList!=null&&selectInsList.length>0">
								<div class="item-list clearfix fb">
									<div class="item-before tr">保险费用</div>
									<div class="item-line"></div>
									<div class="item-after">
										<em class="ft12">¥</em>
										{{ InsureTotalPrice }}
									</div>
								</div>
							</div>

							<div class="item-box" v-show="false">
								<div class="item-list clearfix fb">
									<div class="item-before tr">搭配总价</div>
									<div class="item-line"></div>
									<div class="item-after">
										<em class="ft12">&yen;</em>
										12020
									</div>
								</div>
								<div class="item-list clearfix">
									<div class="item-before tr">目的地推荐</div>
									<div class="item-line"></div>
									<div class="item-after">
										<em class="ft12">&yen;</em>
										900
										<em class="ft12">X1</em>
									</div>
								</div>
								<div class="item-list clearfix">
									<div class="item-before tr">中转地推荐</div>
									<div class="item-line"></div>
									<div class="item-after">
										<em class="ft12">&yen;</em>
										900
										<em class="ft12">X1</em>
									</div>
								</div>
								<div class="item-list clearfix">
									<div class="item-before tr">航司推荐</div>
									<div class="item-line"></div>
									<div class="item-after">
										<em class="ft12">&yen;</em>
										900
										<em class="ft12">X1</em>
									</div>
								</div>
							</div>

							<div class="item-box" v-if="selectCoupon!=null">
								<div class="item-list clearfix fb">
									<div class="item-before tr">现金劵</div>
									<div class="item-line"></div>
									<div class="item-after">
										-
										<em class="ft12">¥</em>
										{{selectCoupon.CcpAmounts}}
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="clearfix  position-r mt20" style="background: #fff;" v-if="TuijianHotel!=null">
						<el-row type="flex" justify="space-between" class="jiudian-box">
							<span class="title">悉尼热门酒店推荐</span>
							<div class="price">
								<span>更多</span>
								<span class="iconfont icon-chalv-xiangzuo"></span>
							</div>
						</el-row>

						<div class="img-box" v-for="(item, index) in TuijianHotel" :key="index">
							<img :src="item.DistrictImgUrl" alt="" style="width: 100%; height: 226px;" />
							<div class="img-bottom">
								<el-row type="flex" justify="space-between">
									<el-col :span="20">{{item.DistrictName}}</el-col>
									<div class="price">
										<span>￥</span>
										<span class="ft18">912</span>
										<span>起</span>
									</div>
								</el-row>
								<div>
									<span>星级：</span>
									<span class="iconfont icon-xingxing red"></span>
								</div>
								<div>
									<span>地址：</span>
									<span>54 North Steyne 2095 Manly NW Australia</span>
								</div>
								<div>
									<span class="iconfont icon-tingchechang"></span>
									<span class=" mr20">停车场</span>
									<span class="iconfont icon-wifi"></span>
									<span class="mr20">wifi</span>
									<span class="iconfont icon-youyong"></span>
									<span>游泳池</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</el-col>
		</el-row>

		<!-- <SelectPerson :showOftenPassenger="showOftenPassenger" @hideSelectPassengerBox="hideSelectPassengerBox" /> -->

		<!--常用旅客-->
		<selectPassenger :showOftenPassenger="showOftenPassenger" :IsChinaCity="IsChinaCity"
			@hideSelectPassengerBox="hideSelectPassengerBox" @bindSelectPassenger="bindSelectPassenger">
		</selectPassenger>

		<!-- <desModal :titleRight="openAddress == 2" title="新增地址" :footerNone="true" :delShow="true" class="person-modal"
			:showClose="false" widthNum="600px" @clickDelAction="clickDel">
			<el-row type="flex" justify="space-between" class="pd10" align="middle">
				<el-col :span="4" style="text-align: right;margin-right:10px;"><span>选择地区</span></el-col>
				<el-col :span="19">
					<el-row type="flex" justify="space-between">
						<el-col :span="7">
							<el-select v-model="value" placeholder="请选择" style="width:100%;">
								<el-option v-for="item in options" :key="item.value" :label="item.label"
									:value="item.value"></el-option>
							</el-select>
						</el-col>
						<el-col :span="7">
							<el-select v-model="value" placeholder="请选择" style="width:100%;">
								<el-option v-for="item in options" :key="item.value" :label="item.label"
									:value="item.value"></el-option>
							</el-select>
						</el-col>
						<el-col :span="7">
							<el-select v-model="value" placeholder="请选择" style="width:100%;">
								<el-option v-for="item in options" :key="item.value" :label="item.label"
									:value="item.value"></el-option>
							</el-select>
						</el-col>
					</el-row>
				</el-col>
			</el-row>
			<el-row type="flex" justify="space-between" class="pd10" align="middle">
				<el-col :span="4" style="text-align: right;margin-right:10px;"><span>详细地址</span></el-col>
				<el-col :span="19">
					<el-row type="flex" justify="space-between">
						<el-col :span="24">
							<el-input placeholder="请输入详细地址"></el-input>
						</el-col>
					</el-row>
				</el-col>
			</el-row>
			<el-row type="flex" justify="space-between" class="pd10" align="middle">
				<el-col :span="4" style="text-align: right;margin-right:10px;"><span>收件人姓名</span></el-col>
				<el-col :span="19">
					<el-row type="flex" justify="space-between">
						<el-col :span="24">
							<el-input placeholder="请输入收件人姓名"></el-input>
						</el-col>
					</el-row>
				</el-col>
			</el-row>
			<el-row type="flex" justify="space-between" class="pd10" align="middle">
				<el-col :span="4" style="text-align: right;margin-right:10px;"><span>收件人手机</span></el-col>
				<el-col :span="19">
					<el-row type="flex" justify="space-between">
						<el-col :span="24">
							<el-input placeholder="收件人手机"></el-input>
						</el-col>
					</el-row>
				</el-col>
			</el-row>
		</desModal> -->

		<!--国内机票退改签-->
		<el-dialog :title="ruleTitle" :visible.sync="showTGQ" width="55%">
			<el-row type="flex" class="flight-hearder" justify="space-between">
				<span></span>
				<!-- <span class="all-checked">全选</span> -->
			</el-row>

			<el-row type="flex" align="middle" class="city-boxone">
				<div class=" cabin-rule" v-if="DomesticLimitConditionInfo!=null">
					<table class="table">
						<tr>
							<td class="td-01" rowspan="3">成人票</td>
							<td colspan="2">
								<div class="feebox">
									<div class="fee-list-first"></div>
									<div class="fee-list"
										v-for="(item_fare, index3) in DomesticLimitConditionInfo.changesDetails"
										:key="index3">
										<div class="fee text-c">{{ item_fare.Content }}</div>
									</div>
								</div>
							</td>
						</tr>
						<tr>
							<td class="td-02">同舱改期费</td>
							<td class="td-03">
								<div class="feebox">
									<div class="fee-list"
										v-for="(item_fare, index3) in DomesticLimitConditionInfo.changesDetails"
										:key="index3">
										<div class="fee">
											<div>
												¥
												<span class="price red">{{ item_fare.AdultFee }}</span>
												/人
											</div>
										</div>
									</div>
								</div>
							</td>
						</tr>
						<tr>
							<td class="td-02">退票费用</td>
							<td class="td-03">
								<div class="feebox">
									<div class="fee-list"
										v-for="(item_fare, index3) in DomesticLimitConditionInfo.refundDetails"
										:key="index3">
										<div class="fee">
											<div>
												¥
												<span class="price red">{{ item_fare.AdultFee }}</span>
												/人
											</div>
										</div>
									</div>
								</div>
							</td>
						</tr>
					</table>

					<div class="cabin-list" v-if="DomesticLimitConditionInfo.changes!=null">
						<div class="tit">改签</div>
						<div class="text">{{ DomesticLimitConditionInfo.changes }}</div>
					</div>
					<div class="cabin-list" v-if="DomesticLimitConditionInfo.refund!=null">
						<div class="tit">退票</div>
						<div class="text">{{ DomesticLimitConditionInfo.refund }}</div>
					</div>
					<div class="cabin-list" v-if="DomesticLimitConditionInfo.baggage!=null">
						<div class="tit">行李</div>
						<div class="text">{{ DomesticLimitConditionInfo.baggage }}</div>
					</div>
					<div class="cabin-list">
						<div class="tit">备注</div>
						<div class="text">{{ DomesticLimitConditionInfo.other }}</div>
					</div>
				</div>
			</el-row>
			<!-- <div class="warn-tip">温馨提示:航班机票退票成功后，对应的保险产品会一起退，如果保险已经生效，则不能再退了！</div> -->
			<div class="text-c" style="padding-top: 40px;">
				<span slot="footer" class="dialog-footer">
					<el-button @click="showTGQ = false">确 定</el-button>
				</span>
			</div>
		</el-dialog>

		<!--提示-->
		<el-dialog class="des-modal" :visible.sync="showOrderLoading" :width="widthNum" show-close="true" title="提示"
			left>
			<div class="text-c">
				<img src="../../assets/serch_loading.gif" class="img" style="width: 60px;position:relative;top:4px;" />
				<p class="ft16 fn mt20 pb20">订单提交中...</p>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	const cityOptions = ['WANG/HUAN(成人)', 'WANG/HUAN(成人)'];
	// import SelectPerson from './components/SelectPerson.vue';


	// import desModal from '../../components/desModal.vue';

	// import changeModal from '../../components/changeModal.vue';
	import personNoData from '../../components/personNoData.vue';
	// import passengerListBox from '../../components/PassengerListBox.vue'; //旅客信息
	// import selectWorker from '../../components/selectWorker.vue';
	import selectPassenger from '../../components/selectPassenger.vue'; //常用旅客

	// import flightMessage from './component/flightMessage.vue';
	// import PassengerList from './component/passengerList.vue';
	import {
		mapGetters,
		mapActions
	} from 'vuex';
	import {
		getTicketSearchInfo,
		setTicketSearchInfo,
		getAccountInfo,
		getBookingFormInfo,
		setBookingFormInfo
	} from '@/utils/localdatas';

	import {
		checkPassenger,
		submitOrder
	} from '@/api/order';

	var util = require('@/utils/util.js');
	var datehelper = require('@/utils/datehelper.js');
	var yqfCommon = require('@/utils/yqfCommon.js'); //通用类库

	//接口
	var flightApi = require('@/api/flightApi.js');
	var memberApi = require('@/api/memberApi.js');
	var destApi = require('@/api/destApi.js'); //目的地
	var yqfApi = require('@/api/yqfApi.js');
	//全局变量
	var that;

	export default {
		components: {
			// SelectPerson,
			selectPassenger,
			// desModal
		},

		data() {
			return {
				checkAll: false,
				checkedCities: ['WANG/HUAN(成人)', 'WANG/HUAN(成人)'],
				cities: cityOptions,
				isIndeterminate: true,
				checkList: ['选中且禁用', '复选框 A'],
				showAll: false,
				options: [{
					value: '选项1',
					label: '护照'
				}],
				value: '',
				textarea: '',
				showOftenPassenger: false,
				openAddress: false,
				value1: '',
				value2: '',
				radio: '',
				openModel: '',
				seatShow: false,
				checked: true,
				show2: true,
				count: '',
				timer: null,
				gotop: false,

				btnLoading: false,
				TicketSearchInfo: '',
				//推荐的酒店
				TuijianHotel: null,
				//预订的航班列表
				BookFlightRouting: [],
				FlightID: '',
				//旅客信息
				// PassengerListData: [],
				priceInfo: {
					TotalPrice: 0,
					AdultPrice: 0,
					AdultTax: 0,
					ChildPrice: 0,
					ChildTax: 0,
					AdultCount: 1,
					ChildCount: 0
				},

				form: {
					sendType: 1,
					address: '',
					linkman: '',
					area: '0086',
					phone: '',
					email: '',
					remark: '',
					isseat: 0,
					seat: '靠窗',
					PassengerListData: []
				},
				VCodeVal: '',
				IsChinaCity: false,
				doscText: '',
				ruleInfoList: '',
				ruleRemark: '',
				Countrys: [],
				LimitCondition: '',
				currentItem: '',

				jingwailist: [], //境外
				hangyilist: [], //航意

				selectInsList: '', //选择保险
				selectCoupon: null, //选择的现金券
				selectvisa: null, //选择的签证
				isDisabled: false,
				isAgreeRule: false, //是否已阅读条款
				accountInfo: null, //用户信息
				//国内机票退改签
				DomesticLimitConditionInfo: null,
				showTGQ: false,
				ruleTitle: '',
				CustomerRules: null, //航司规则
				showOrderLoading: false,
				tiaokuanArray: [{
						name: "个人信息授权声明（接送机或当地交通）",
						page: "trafic"
					},
					{
						name: "个人信息授权声明（酒店）",
						page: "hotel"
					},
					{
						name: "个人信息授权声明（签证）",
						page: "visa"
					},
					{
						name: "个人信息授权声明（个人信息传输境外声明）",
						page: "status1"
					},
					{
						name: "个人信息授权声明",
						page: "status2"
					},
					{
						name: "个人信息授权声明（预订自由行）",
						page: "product"
					},
					{
						name: "航班信息免责声明",
						page: "flight"
					},
					{
						name: "旅客文明乘机须知",
						page: "lvke"
					},
				]
			};
		},
		created() {
			that = this;
			var query = that.$route.query;
			console.log(query);

			var accountInfo = getAccountInfo();

			if (accountInfo != null) {
				that.accountInfo = accountInfo;
				that.form.linkman = accountInfo.Name;
				that.form.email = accountInfo.Email;
				that.form.phone = accountInfo.Mobile;
				// that.form.linkman=accountInfo.Name;
			}
			that.TicketSearchInfo = getTicketSearchInfo();
			that.IsChinaCity = that.TicketSearchInfo.IsChinaCity;
			getBookFlightList();
			getCountrys();

			$(window).scrollTop("0");
		},
		mounted() {
			// 此处true需要加上，不加滚动事件可能绑定不成功
			window.addEventListener('scroll', this.handleScroll, true);
		},
		methods: {
			bindReSearch: function(e) {
				if (!that.TicketSearchInfo.IsChinaCity) {
					that.$router.push({
						path: '/Flight/showIntlflight'
					});
				} else {
					that.$router.push({
						path: '/Flight/ShowDomesticFlight'
					});
				}
			},
			//日期格式
			getDataStr(date, str) {
				if (yqfCommon.isNullOrEmpty(date))
					return;
				let _date = datehelper.DateTimeParse(date);
				return _date.toString(str);
			},
			//跳转登录
			bindJumpLogin: function(e) {
				var returnurl = that.$route.path;
				console.log(returnurl);
				util.getLogin(returnurl);
			},

			//详情
			showDetail(e) {
				// this.showFlightDetail = !this.showFlightDetail;

				var thisitem = e.currentTarget.dataset.id;
				console.log(thisitem);
				if (that.currentItem != '' && that.currentItem == thisitem) {
					thisitem = '';
				}
				that.currentItem = thisitem;
			},
			//退改签
			bindShowTGQ(item) {
				console.log(item);


				var item_trip = item.BerthList[0];
				var item_trip = item.BerthList[0];

				that.ruleTitle = item.DepartureName + ' - ' + item.ArriveName + ' ' + item.MarktingAirlineName + ' ' +
					item.trips[0].FlightNO + ' ' + item_trip.BillBerthDescription + ' ' + item_trip.Berth;

				that.showTGQ = true;
				// that.tgqTitle = MarktingAirlineName + " " + FlightNO + " " + item.BillBerthDescription + " " + item
				// .Berth; //"南航CZ6748 商务舱 I";

				flightApi.GetDomesticAirLimitConditionInfo({
						AdultPrice: item_trip.Price,
						ChildPrice: item_trip.ChildPrice,
						Airline: item_trip.Airline,
						DepartureDate: item_trip.DepartureDate,
						Berth: item_trip.Berth,
						id: item_trip.id,
					},
					function(result) {
						// console.log(result);;
						if (result.code == 0) {
							that.DomesticLimitConditionInfo = result.data.limitConditionInfo;
						} else {

						}
					}
				);

			},
			//获取验证码
			bindGetVCode(e) {
				if (yqfCommon.isNullOrEmpty(that.form.phone)) {
					that.$alert('请输入手机号码');
					return;
				}
				SendBandSMSCode();
			},


			hide() {
				this.showAll = false;
			},
			show() {
				this.showAll = true;
			},
			handleScroll() {
				let scrolltop = document.documentElement.scrollTop || document.body.scrollTop;

				scrolltop > 900 ? (this.gotop = true) : (this.gotop = false);
				// console.log(scrolltop, this.gotop);
			},

			openAdress(e) {
				this.openAddress = e;
			},
			clickDel() {
				this.openAddress = '';
			},
			handleCheckAllChange(val) {
				this.checkedCities = val ? cityOptions : [];
				this.isIndeterminate = false;
			},
			handleCheckedCitiesChange(value) {
				let checkedCount = value.length;
				this.checkAll = checkedCount === this.cities.length;
				this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
			},

			clickseatShow() {
				this.seatShow = !this.seatShow;
			},

			//新增旅客
			addPassenger(e) {
				var PassengerListData = that.form.PassengerListData;
				if (PassengerListData.length >= 9) {
					return;
				}
				var new_passenger = {
					ID: Date.now(),
					Man_id: '',
					Man_xing: '',
					Man_ming: '',
					Man_xingming: '',
					Man_type_p: 'ADT',
					Man_sex: '1',
					Birthday: '',
					CertificateType: 'PP',
					CertificateDate: '',
					CertificateNo: '',
					Man_SaveOrNo: '0',
					Needinsure: '0',
					NeedVisa: '0',
					Nationality: 'CN',
					Seat: '无',
					ExtWorkNo: '',
					Phone: '',
					CertificateTypeList: PassengerListData[0].CertificateTypeList
				};
				PassengerListData.push(new_passenger);
				that.form.PassengerListData = PassengerListData;
				setPriceInfo();
			},
			//删除一个旅客
			delPassenger(idx) {
				var PassengerListData = that.form.PassengerListData;
				if (PassengerListData.length <= 1) {
					return;
				}
				PassengerListData.splice(idx, 1);
				that.form.PassengerListData = PassengerListData;
				setPriceInfo();
			},
			//
			changePassengerInput(e, idx) {
				console.log(idx);
				var form = that.form;
				// var PassengerListData = that.form.PassengerListData;
				form.PassengerListData[idx].Man_xing = e.currentTarget.value;
				that.form = form;
			},
			//选择常旅客
			bindShowOftenPassenger() {
				console.log(1);
				this.showOftenPassenger = true;
			},
			hideSelectPassengerBox() {
				this.showOftenPassenger = false;
			},
			//确认选择常用旅客
			bindSelectPassenger(selectPassengerList) {
				this.showOftenPassenger = false;
				console.log(selectPassengerList);
				if (selectPassengerList.length == 0 || selectPassengerList == null) {
					return;
				}
				let PassengerList = that.form.PassengerListData;

				// let PassengerList = [];
				console.log(PassengerList);

				for (let idx = 0; idx < selectPassengerList.length; idx++) {
					let Man_xing = '',
						Man_ming = '';
					let Passenger = selectPassengerList[idx];

					if (that.IsChinaCity && Passenger.FullName != '') {
						Man_xing = Passenger.FirstNameCn;
						Man_ming = Passenger.LastNameCn;
					} else {
						Man_xing = Passenger.LastNameEn;
						Man_ming = Passenger.FirstNameEn;
					}

					var new_Passenger = {};
					new_Passenger.Man_id = Passenger.PersonCode;
					new_Passenger.Man_type_p = Passenger.PassengerAgeSection;
					new_Passenger.Man_type_p_name = Passenger.PassengerAgeSection == 'CHD' ? '小孩' : '成人';
					new_Passenger.Man_xing = Man_xing;
					new_Passenger.Man_ming = Man_ming;
					new_Passenger.Birthday = Passenger.BirthDay;
					new_Passenger.Nationality = Passenger.Nationality;
					new_Passenger.Man_sex = Passenger.Sex;
					new_Passenger.Phone = Passenger.Phone;
					let cre_index = 0;

					if (that.IsChinaCity) {
						for (var i = 0; i < Passenger.WXCredentials.length; i++) {
							if (Passenger.WXCredentials[i].ProofType == 'ID') {
								cre_index = i;
							}
						}
					} else {
						for (var i = 0; i < Passenger.WXCredentials.length; i++) {
							if (Passenger.WXCredentials[i].ProofType == 'PP') {
								cre_index = i;
							}
						}
					}

					new_Passenger.CertificateDate = Passenger.WXCredentials[cre_index].EndDate;
					new_Passenger.CertificateNo = Passenger.WXCredentials[cre_index].Number;
					new_Passenger.CertificateTypeIndex = Passenger.WXCredentials[cre_index].CertificateTypeIndex;
					new_Passenger.CertificateType = Passenger.WXCredentials[cre_index].ProofType;
					new_Passenger.CertificateTypeName = Passenger.WXCredentials[cre_index].ProofName;

					new_Passenger.CertificateTypeList = PassengerList[0].CertificateTypeList;
					console.log(new_Passenger);
					// PassengerList[idx] = new_Passenger; //
					PassengerList.push(new_Passenger);
				}

				console.log(PassengerList);
				that.form.PassengerListData = PassengerList;
				setPriceInfo();
			},
			//检查身份证
			bindCheckCerNo(idx) {
				console.log(1111111);
				let PassengerListData = that.form.PassengerListData;
				console.log(PassengerListData[idx]);
				if (PassengerListData[idx].CertificateType == '身份证' || PassengerListData[idx].CertificateType == 'ID') {
					let cardInfo = yqfCommon.getIDCardInfo(PassengerListData[idx].CertificateNo);
					console.log(cardInfo);
					if (cardInfo != null) {
						PassengerListData[idx].Birthday = cardInfo.birthday;
						PassengerListData[idx].Man_sex = cardInfo.sex == '男' ? '1' : '0';
						if (cardInfo.age < childAge) {
							PassengerListData[idx].Man_type_p = 'CHD';
						} else {
							PassengerListData[idx].Man_type_p = 'ADT';
						}

						that.form.PassengerListData = PassengerListData;
						setPriceInfo();
					}
				}
			},
			//判断日期
			changeBirthday(idx) {
				let PassengerListData = that.form.PassengerListData;
				let age = yqfCommon.getAgeByBirthday(PassengerListData[idx].Birthday);
				console.log(age);
				if (age < childAge) {
					PassengerListData[idx].Man_type_p = 'CHD';
				} else {
					PassengerListData[idx].Man_type_p = 'ADT';
				}
				setPriceInfo();
			},

			//选择保险
			bindInsuranceItemClick: function(e) {
				console.log(111111);
				let selectIndex = e.currentTarget.dataset.index;
				let selectid = e.currentTarget.dataset.id;
				let typecode = e.currentTarget.dataset.typecode;

				let hangyilist = that.hangyilist;
				let jingwailist = that.jingwailist;

				if (typecode == 'hy') {
					hangyilist[selectIndex].checked = !hangyilist[selectIndex].checked;
				} else {
					jingwailist[selectIndex].checked = !jingwailist[selectIndex].checked;
				}
				// console.log(selectid);
				// let bookInsuranceInfoList = that.bookInsuranceInfoList;
				that.hangyilist = hangyilist;
				that.jingwailist = jingwailist;
				setPriceInfo();
			},
			//保险详情
			bindShowInsDetailInfo: function(item) {
				let url = "https://www.yiqifei.com/Insur/ProductDetail?ProductCode=" + item.ProductCode +
					"&PlanCode=" + item.PlanCode + "";
				window.open(url);
			},
			//协议跳转
			bindRule: function(item) {
				console.log(item);
				window.open(item.url);
				// let url = '/pages/webview/webview?webviewurl=' + encodeURIComponent(item.url);
				// util.navigateTo(url)
			},
			bindToTiaokuan: function(item) {
				window.open("https://m.yqf.com/member/hangsiXieyi?page=" + item.page);
			},
			//确认预定
			bindSubmit: function(e) {
				// if (!that.isAgreeRule) {
				// 	that.$alert('请确认您是否已经阅读并熟知条款')
				// 	return;
				// }
				if (that.CustomerRules != null && that.CustomerRules.length > 0) {
					if (!that.isAgreeRule) {
						that.$alert('请确认您是否已经阅读并熟知条款')
						return;
					}
				}


				var values = that.form;

				console.log(values);
				//配送方式
				let SendType = values.sendType;

				let BookFlightRouting = JSON.stringify(that.BookFlightRouting);
				let PassengerList = values.PassengerListData;

				//旅客信息
				for (var i = 0; i < PassengerList.length; i++) {

					let Nationality = PassengerList[i].Nationality;
					if (Nationality instanceof Array) {
						PassengerList[i].Nationality = Nationality[0];
					}
				}
				PassengerList = JSON.stringify(values.PassengerListData);

				// let InsurancelList = JSON.stringify(this.InsurancelList);
				let accountinfo = '';
				if (!yqfCommon.isNullOrEmpty(that.accountInfo)) {
					accountinfo = JSON.stringify(that.accountInfo);
				}
				//选择的保险
				let selectInsList = that.selectInsList;
				if (!yqfCommon.isNullOrEmpty(selectInsList)) {
					selectInsList = JSON.stringify(selectInsList);
				} else {
					selectInsList = "";
				}
				//签证
				let selectvisa = that.selectvisa;
				if (!yqfCommon.isNullOrEmpty(selectvisa)) {
					selectvisa = JSON.stringify(selectvisa);
				} else {
					selectvisa = "";
				}
				//选择的现金券
				let selectCoupon = that.selectCoupon;
				if (!yqfCommon.isNullOrEmpty(selectCoupon)) {
					selectCoupon = JSON.stringify(selectCoupon);
				} else {
					selectCoupon = "";
				}

				//机票查询信息
				let ticketsearchinfoJson = JSON.stringify(that.TicketSearchInfo);

				let parm = {
					BookFlightRouting: BookFlightRouting, //机票预订信息
					ticketsearchinfoJson: ticketsearchinfoJson, //机票查询信息

					PassengerList: PassengerList, //旅客信息					

					accountinfo: accountinfo, //账号信息
					vcode: that.VCodeVal,

					// isneedinsure: values.isneedinsure,
					SendLinkMan: values.linkman,
					SendLinkMobile: values.phone,
					SendLinkEmail: values.email,
					SendLinkRemark: values.remark,
					SendType: SendType,
					IsChinaCity: that.IsChinaCity,

					selectInsList: selectInsList, //保险
					selectvisa: selectvisa, //签证
					selectCoupon: selectCoupon, //现金券

					// isbingPhone: that.isbingPhone,
					// VCode: values.VCode,
					// payTypeID: that.payTypeID,
					ordertype: 'ticket',
					isus: 'false'
				};
				console.log(parm);
				///Order/orderResult
				that.showOrderLoading = true;
				submitOrder(parm, function(result) {
					that.showOrderLoading = false;
					// console.log(result);;
					// util.hideLoading();
					if (result.code == 0) {
						// that.TicketSearchInfo.OrderNo = '';
						// that.TicketSearchInfo.IsChange = false;
						// localdatas.setTicketSearchInfo(that.TicketSearchInfo);		
						that.$router.push({
							path: '/Order/orderResult',
							query: {
								id: result.data.LR_BillNO
							}
						});
					} else if (result.code == -1) {
						that.$router.push({
							path: '/'
						});
					} else {
						that.$alert(result.msg);
					}
				});
			},
		}
	};

	//获取航班列表数据
	function getBookFlightList() {
		flightApi.GetConfirmBookFlightInfo({
				ins: 'true'
			},
			function(result) {
				// console.log(result);;
				if (result.code == 0) {
					let BookFlightRouting = JSON.parse(result.data.BookFlightRouting);
					let LimitCondition = result.data.LimitCondition;
					let InsurancelList = [];

					that.BookFlightRouting = BookFlightRouting;
					that.FlightID = BookFlightRouting[0].ID;
					that.LimitCondition = LimitCondition;
					let priceInfo = that.priceInfo;
					priceInfo.TotalPrice = result.data.TotalPrice;
					priceInfo.AdultPrice = result.data.AdultPrice;
					priceInfo.AdultTax = result.data.AdultTax;
					priceInfo.ChildPrice = result.data.ChildPrice;
					priceInfo.ChildTax = result.data.ChildTax;
					that.priceInfo = priceInfo;
					getPassengerList();
					getBookTuijianInfo();
					GetAirwaysCustomerRule();
				} else {
					that.$confirm(result.msg, '提示', {
						distinguishCancelAndClose: true,
						confirmButtonText: '确定'
					}).then(() => {
						that.$router.push({
							path: '/Flight'
						});
					});
				}
			}
		);
	}

	//旅客信息
	function getPassengerList() {
		let form = getBookingFormInfo();
		// if (form != null) {
		// 	that.form = form;
		// }
		console.log(form);
		// if (util.isNullOrEmpty(form)) {
		memberApi.GetPassengerList({}, function(result) {
			// console.log(result);;
			if (result.code == 0) {
				let PassengerListData = JSON.parse(result.data.PassengerList);
				console.log(PassengerListData);
				that.form.PassengerListData = PassengerListData;
				setPriceInfo();
			}
		});
		// } else {
		// 	setPriceInfo();
		// }
	}
	//价格信息
	function setPriceInfo() {
		let priceInfo = that.priceInfo;
		let PassengerList = that.form.PassengerListData;
		let adultCount = 0;
		let childCount = 0;
		let InsureTotalPrice = 0;
		let visaPrice = 0;
		let CouponAmount = 0;

		let TotalPrice = priceInfo.TotalPrice;
		let AdultTotalPrice = priceInfo.AdultPrice + priceInfo.AdultTax;
		let ChildTotalPrice = priceInfo.ChildPrice + priceInfo.ChildTax;

		for (var i = 0; i < PassengerList.length; i++) {
			if (PassengerList[i].Man_type_p == 'CHD') {
				childCount++;
			} else {
				adultCount++;
			}
		}

		AdultTotalPrice = AdultTotalPrice * adultCount;
		ChildTotalPrice = ChildTotalPrice * childCount;


		//保险费用
		var adtinsureprice = 0; //成人保险
		var chdinsureprice = 0; //小孩保险  
		let selectInsList = [];
		//航意险
		let selectInshangyilist = that.hangyilist.filter(function(item) {
			return item.checked == true;
		});
		if (selectInshangyilist.length > 0) {
			selectInsList = selectInshangyilist;
		}
		let selectInsjingwailist = that.jingwailist.filter(function(item) {
			return item.checked == true;
		});
		if (selectInsjingwailist.length > 0) {
			if (selectInsList.length > 0) {
				selectInsList = selectInsList.concat(selectInsjingwailist);
			} else {
				selectInsList = selectInsjingwailist;
			}
		}
		//console.log(selectInsList);
		if (selectInsList.length > 0) {
			for (var i = 0; i < selectInsList.length; i++) {
				var ins_item = selectInsList[i];
				if (ins_item.SortID == 1) {
					adtinsureprice += (ins_item.ADTPrice * parseInt(ins_item.TripCount));
					chdinsureprice += (ins_item.CHDPrice * parseInt(ins_item.TripCount));
				} else {
					adtinsureprice += ins_item.ADTPrice;
					chdinsureprice += ins_item.CHDPrice;
				}
			}
			InsureTotalPrice = (adtinsureprice * adultCount) + (chdinsureprice * childCount);
		}
		console.log('保险费用:' + InsureTotalPrice);
		that.selectInsList = selectInsList;
		that.InsureTotalPrice = InsureTotalPrice;

		//签证
		var selectvisa = that.selectvisa;
		if (selectvisa != null) {
			visaPrice = selectvisa.UnitPrice * selectvisa.VisaPassengers.length;
		}
		//现金券	
		let selectCoupon = that.selectCoupon;
		if (!yqfCommon.isNullOrEmpty(selectCoupon)) {
			CouponAmount = selectCoupon.CcpAmounts;
		}

		// TotalPrice = AdultTotalPrice + ChildTotalPrice;
		TotalPrice = AdultTotalPrice + ChildTotalPrice + InsureTotalPrice + visaPrice - CouponAmount;

		priceInfo.AdultCount = adultCount;
		priceInfo.ChildCount = childCount;
		priceInfo.TotalPrice = TotalPrice;
		that.priceInfo = priceInfo;
	}
	//获取退改签
	function getFareRuleInfo(flightID) {
		var parm = {
			// id: flightID
		};
		flightApi.GetFareRuleInfo(parm, function(result) {
			// console.log(result);;
			if (result.code == 0) {
				that.ruleInfoList = result.data.ruleInfoList;
				that.ruleRemark = result.data.ruleRemark;
			} else {
				that.$confirm(result.msg, '提示', {
					distinguishCancelAndClose: true,
					confirmButtonText: '确定'
				}).then(() => {
					that.showLimitCondition = false;
				});
			}
		});
	}
	//获取国家列表
	function getCountrys() {
		destApi.GetCountrys({}, function(result) {
			// console.log(result);;
			if (result.code == 0) {
				that.Countrys = result.data.Countrys;
			}
		});
	}
	//获取推荐信息
	function getBookTuijianInfo() {
		// util.showLoding();
		var parm = {
			_bookflightrouting: JSON.stringify(that.BookFlightRouting),
			ticketsearchinfo: JSON.stringify(that.TicketSearchInfo),
		};
		flightApi.GetBookTuijianInfo(parm, function(result) {
			// console.log(result);;
			if (result.code == 0) {
				let bookInsuranceInfoList = result.data.bookInsuranceInfoList;
				let jingwailist = result.data.jingwailist;
				let hangyilist = result.data.hangyilist;
				if (jingwailist != null && jingwailist != '') {
					for (let item in jingwailist) {
						jingwailist[item].checked = false;
					}
				}
				if (hangyilist != null && hangyilist != '') {
					for (let item in hangyilist) {
						hangyilist[item].checked = false;
					}
				}
				for (let item in bookInsuranceInfoList) {
					bookInsuranceInfoList[item].checked = false;
				}

				let visaInfoList = result.data.visaInfoList;
				console.log(visaInfoList);
				if (visaInfoList != null && visaInfoList.length > 0) {
					// storagehelper.setVisaListData(visaInfoList);
					that.isHaveVisa = true;
				}
				// } else {
				// 	storagehelper.setVisaListData(null);
				// }
				that.bookInsuranceInfoList = bookInsuranceInfoList;
				that.jingwailist = jingwailist;
				that.hangyilist = hangyilist;
				// that.isNoData=bookInsuranceInfoList == null || bookInsuranceInfoList == "" || bookInsuranceInfoList.lenght == 0;
			} else {
				// util.wxalert(result.msg);
			}
		});
	}

	//推荐酒店
	function GetTuiJianHotelInfo() {
		var parm = {
			ticketsearchinfo: JSON.stringify(that.TicketSearchInfo)
		};
		flightApi.GetTuiJianHotelInfo(parm, function(result) {
			// console.log(result);;
			if (result.code == 0) {
				that.TuijianHotel = result.data.TuijianHotel;
			}
		});
	}
	//获取航司协议
	function GetAirwaysCustomerRule() {
		// util.showLoding();
		let parm = {
			CarrierCode: that.BookFlightRouting[0].Airline,
			ProductCategoryID: that.IsChinaCity ? 8 : 9
		};
		flightApi.GetAirwaysCustomerRule(parm, function(result) {
			// console.log(result);;
			if (result.code == 0) {
				that.CustomerRules = result.data.CustomerRules;
				// if(that.CustomerRules!=null&&that.CustomerRules.Length>0){

				// }
			} else {
				console.log(result.msg);
				that.isAgreeRule = true;
			}
		});
	}
	//发送验证码
	function SendBandSMSCode() {
		let parm = {
			loginMobile: that.form.phone,
			isneedlogin: false,
			areaCode: '',
			loginCode: '',
		};
		yqfApi.SendBandSMSCode(
			parm,
			function(result) {
				// console.log(result);;
				if (result.code == 0) {
					setSeconds();
				} else {
					that.$alert(result.msg);
				}
			},
			false
		);
	}
	//获取验证码
	function setSeconds() {
		const TIME_COUNT = 60;
		if (!that.timer) {
			that.count = TIME_COUNT;
			that.show2 = false;
			that.timer = setInterval(() => {
				if (that.count > 0 && that.count <= TIME_COUNT) {
					that.count--;
				} else {
					that.show2 = true;
					clearInterval(that.timer);
					that.timer = null;
				}
			}, 1000);
		}
	}
</script>

<style lang="scss">
	@import '../../style/Flight/flight.scss';
</style>
